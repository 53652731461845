<template>
  <v-app>
    <v-app-bar app color="" hide-on-scroll>
      <v-app-bar-nav-icon @click="nav = true"></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <img src="@/assets/logo.png" height="40px" />
    </v-app-bar>
    <v-navigation-drawer class="d-flex flex-column" height="auto" app v-model="nav" fixed temporary>
      <v-list nav>
        <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
          <v-list-item @click.stop="changeVerkauft">
            <v-list-item-icon>
              <v-icon>mdi-shopping</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <div class="d-flex flex-row">
                <v-switch :value="verkaufte" hide-details class="mt-0 pt-0"></v-switch>
                <span class="text-caption pt-1">verkaufte</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click.stop="changeStateSave">
            <v-list-item-icon>
              <v-icon>mdi-lan-disconnect</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <div class="d-flex flex-row">
                <v-switch :value="useState" hide-details class="mt-0 pt-0"></v-switch>
                <span class="text-caption pt-1">Ausfallschutz</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-footer class="text-caption pl-5 mt-auto">Version {{ appversion }}</v-footer>
    </v-navigation-drawer>
    <v-main>
      <erfassung :verkaufte="verkaufte" :useState="useState"/>
    </v-main>
    <v-footer class="text-caption text-center pl-5 mt-auto d-block py-0">Version {{ appversion }}</v-footer>
  </v-app>
</template>

<script>

import erfassung from './components/erfassung';
import {version} from './../package'

export default {
  name: 'App',
  components: {
    erfassung
  },

  data: () => ({
    nav: false,
    verkaufte: false,
    useState: false,
    group: null,
    appversion: "",
  }),
  mounted(){
    console.log(process.env);
    this.appversion = version
    this.verkaufte = window.localStorage.getItem('verkaufte')=='1'
    this.useState = window.localStorage.getItem('useState')=='1'
  },
  methods: {
      changeVerkauft(){
        this.verkaufte = !this.verkaufte
        window.localStorage.setItem('verkaufte',this.verkaufte ? '1' : '0')
      },
      changeStateSave(){
        this.useState = !this.useState
        window.localStorage.setItem('useState',this.useState ? '1' : '0')
      },
      logout(){
        window.localStorage.removeItem("token")
        document.location.reload()
      },
  }
};
</script>
