<template>
  <v-container>
    <v-overlay :value="saving">
      <v-progress-circular :size="140" :width="14" color="white" indeterminate>speichern</v-progress-circular>
    </v-overlay>
    <v-snackbar timeout="3000" v-model="snackbar" centered class="text-center" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
    <v-form v-if="page=='login'" ref="loginform">
      <h2>Login</h2>
      <v-text-field prepend-inner-icon="mdi-account" v-model="user" ref="user" label="Benutzer" placeholder="Benutzer" class="mt-3" outlined hide-details clearable></v-text-field>
      <v-text-field prepend-inner-icon="mdi-key" type="password" v-model="pwd" ref="pwd" label="Kennwort" placeholder="Kennwort" class="mt-3" outlined hide-details clearable></v-text-field>
      <v-btn :loading="loading" color="primary" @click="login" class="mt-3" block>Login</v-btn>
    </v-form>
    <v-form ref="form">
      <div v-if="page=='container'">
        <h2>Container-Nummer:</h2>
        <v-row no-gutters dense class="containernummer">
          <v-col cols="4" style="min-width: 130px; max-width: 130px;" class="flex-grow-1 flex-shrink-0">
            <v-text-field :disabled="bearbeitungsmodus" :rules="code1rules" @focus="selectAllByRef('code1')" @keypress="filteralpha" :loading="ocr_running && container.code1==''" @input="codeeingabe('code1',$event)" pattern="[A-Z]*" maxlength="3" suffix="U" v-model="container.code1" ref="code1" placeholder="A-Z" class="mr-1" outlined hide-details></v-text-field>
          </v-col>
          <v-col cols="6" style="min-width: 150px; max-width: 160px;" class="flex-grow-0 flex-shrink-1">
            <v-text-field :disabled="bearbeitungsmodus" :rules="code2rules" @focus="selectAllByRef('code2')" @keypress="filternumbers" min=0 max=999999 type="number"  pattern="[0-9]*" inputmode="numeric" :loading="ocr_running && container.code2==''" @input="codeeingabe('code2',$event)" maxlength="6" v-model="container.code2" ref="code2" placeholder="0-9" class="mr-1" outlined hide-details></v-text-field>
          </v-col>
          <v-col cols="2" style="min-width: 40px; max-width: 45px;" class="flex-grow-0 flex-shrink-1">
            <v-text-field :disabled="true" :rules="code3rules" @focus="selectAllByRef('code3')" min=0 max=9 type="number" :loading="ocr_running && container.code3==''" @input="codeeingabe('code3',$event)" maxlength="1" v-model.number="container.code3" ref="code3" placeholder="" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-btn class="mb-3 mt-3" :loading="loading" x-large color="primary" @click="laden" block>Laden <v-icon right dark>mdi-play</v-icon></v-btn>
        <div class="legende d-flex justify-space-between text-caption">
          <div><span class="ALPSContainer"></span>ISO</div>
          <div><span class="ALPSRaumContainer"></span>RAUM</div>
          <div><span class="ALPSMietContainer"></span>MIET</div>
        </div>
        <v-list class="liste" three-line v-if="data_loaded && !bearbeitungsmodus && containers.length>0">
          <template v-for="(item,idx) in containerliste">
            <v-divider v-if="idx==0" :key="idx+'idx'"></v-divider>
            <v-list-item two-line class="px-0" :class="item.tbl" :key="idx">
              <v-list-item-avatar class="my-6">
                <v-img v-if="item && item.images && item.images[0]" :src="item.images[0].dataUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="d-flex">{{ getNummer(item) }}<v-spacer></v-spacer><div v-if="item.LfdNr" class="text-caption">Lfd-Nr. {{ item.LfdNr }}</div></v-list-item-title>
                <v-list-item-subtitle>{{ item.Color }} </v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.Length }} {{ item.Width }} {{ item.Height }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="my-6 mr-2">
                <v-btn fab dark small color="primary" @click="bearbeiten(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              </v-list-item-action>
              <!--<div class="d-flex">
                <v-btn class="mt-3" @click="resetForm" depressed>abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mt-3" color="primary" @click="bearbeiten" depressed>bearbeiten</v-btn>
              </div>-->
            </v-list-item>
            <v-divider :key="idx+'idx2'"></v-divider>
          </template>
          <!--
          <div v-for="(image,idx) of imagefiles" :key="idx">
            <v-img class="mt-2" :src="image.dataUrl" ></v-img>
          </div>
          -->
        </v-list>
        <div v-else-if="data_loaded && !bearbeitungsmodus && containers.length<=0 && containerNrOk" class="d-flex justify-center">
          <v-btn class="mb-3 mt-15" elevation="6" x-large rounded color="primary" @click="anlegen" >Anlegen <v-icon right dark>mdi-plus</v-icon></v-btn>
        </div>
        <div v-else-if="!bearbeitungsmodus && false">
          <div class="d-flex justify-center mt-6">
            <v-img src="@/assets/logo.png" width="80%" class="" contain max-height="200px" max-width="40%"></v-img>
          </div>
          <div class="text-center text-h5 mt-5">Zum beginnen Container-Nummer eingeben und auf "Laden" klicken</div>
        </div>
      </div>
      <div v-if="bearbeitungsmodus && page=='container'" style="position:relative">
        <div v-if="container.LfdNr" class="text-caption text-center">Lfd-Nr. {{ container.LfdNr }}</div>
        <v-expansion-panels accordion class="panels" :class="container.tbl">
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              <div>
                <v-icon class="mr-2">mdi-camera</v-icon>
                <span class="text-h6">Bilder</span>
              </div>
              <template v-slot:actions>
                <v-badge v-if="!loadingImages" :color="imagefiles.length > 0 ? 'green' : 'red'" :content="imagefiles.length">
                  <v-icon>$expand</v-icon>
                </v-badge>
                <v-badge v-else :color="imagefiles.length > 0 ? 'green' : 'red'" class="badge_loading">
                  <v-icon>$expand</v-icon>
                  <template v-slot:badge>
                    <v-progress-circular size="14" width="2" indeterminate color="white"></v-progress-circular>
                  </template>
                </v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="text-center bilder">
                <image-uploader v-if="false" :preview="false" className="fileinput" capture="environment" :debug="0" :maxWidth="1024" :maxHeight="1024" :quality="1.0" :doNotResize="['gif']" :autoRotate="true" outputFormat="verbose" @input="appendImage">
                  <label for="fileInput" slot="upload-label">
                  <v-icon x-large color="primary">mdi-camera-plus</v-icon><br />
                    <!--<figure>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <path class="path1" d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"></path>
                      </svg>
                    </figure>-->
                    <span class="upload-caption primary--text">Aufnehmen</span>
                  </label>
                </image-uploader>
                <div v-else>
                  <label v-if="!uploadingImages" @click="$refs.imageupload.click()" style="cursor:pointer">
                    <v-icon x-large color="green" class="mdi-48px">mdi-camera-plus</v-icon><br />
                    <span class="upload-caption green--text">Aufnehmen</span>
                  </label>
                  <v-progress-circular size="72" indeterminate v-else color="primary">
                    <v-icon color="grey" large>mdi-cloud-upload</v-icon>
                  </v-progress-circular>
                  <input class="d-none" @change="handleImage" ref="imageupload" type="file" capture="environment" accept="image/*">
                </div>
              </div>
              <!--<div v-html="ocr_result"></div>-->
              <div v-for="(image,idx) of imagefiles" :key="idx" style="position:relative">
                <v-btn v-if="image.info.state!='deleted'" color="red" @click="delImg(idx)" class="ma-1"  fab absolute right style="right:0px"><v-icon color="white" dark>mdi-delete</v-icon></v-btn>
                <v-img v-if="image.info.state!='deleted'" class="mt-2 imageeditable" :src="image.dataThumb ? image.dataThumb : image.dataUrl" ></v-img>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              <div class="d-flex">
                <v-icon class="mr-2">mdi-help-box</v-icon>
                <span class="text-h6">Art/Typ</span>
                <v-spacer></v-spacer>
                <span class="text-h6 mr-5">{{ selectedTyp }}</span>
              </div>
              <template v-slot:actions>
                <v-badge class="panelbadge" :color="container.tbl ? 'green' : 'red'">
                  <v-icon>$expand</v-icon>
                </v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select :items="auswahlen.typen" v-model="container.tbl" item-value="tbl" :disabled="container.LfdNr>0" item-text="name" label="Art" outlined></v-select>
              <div v-if="container.tbl=='ALPSRaumContainer'">
                <v-text-field type="number" max="9999" v-model="container.Length" label="Länge" append-icon="" suffix="mm" outlined></v-text-field>
                <v-text-field type="number" max="9999" v-model="container.Height" label="Höhe" append-icon="" suffix="mm" outlined></v-text-field>
                <v-text-field type="number" max="9999" v-model="container.Width" label="Breite" append-icon="" suffix="mm" outlined></v-text-field>
              </div>
              <div v-else>
                <v-select v-if="auswahlen[container.tbl]" :items="auswahlen[container.tbl]['Length']" v-model="container.Length" label="Länge" append-icon="" suffix="ft" outlined></v-select>
                <v-select v-if="auswahlen[container.tbl]" :items="auswahlen[container.tbl]['Height']" v-model="container.Height" label="Höhe" append-icon="" suffix="ft" outlined></v-select>
              </div>
              <div v-if="container.tbl=='ALPSContainer'">
                <v-select v-if="auswahlen[container.tbl]" :items="auswahlen[container.tbl]['Typ']" v-model="container.Typ" label="Typ" outlined></v-select>
                <v-card v-if="container.Typ=='Reefer'">
                  <v-card-title>REEFER / Kühlaggregat</v-card-title>
                  <v-card-text>
                    <v-text-field v-model="container.ManufacturerCoolingUnit" label="Cooling Unit" outlined></v-text-field>
                    <v-combobox v-model="container.YOMCoolingUnit" :items="baujahre" label="Baujahr" outlined></v-combobox>
                    <v-text-field v-model="container.SerialNoCoolingUnit" label="Serial" outlined></v-text-field>
                  </v-card-text>
                </v-card>
              </div>
              <v-card v-else-if="container.tbl=='ALPSRaumContainer'">
                <v-card-title>RAUM</v-card-title>
                <v-card-text>
                  <v-select :items="auswahlen['ALPSContainer']['Color']" v-model="container.ColorAussenwand" append-icon="" label="Farbe Wand" outlined clearable></v-select>
                  <v-select :items="auswahlen['ALPSContainer']['Color']" v-model="container.ColorRahmen" append-icon="" label="Farbe Rahmen" outlined clearable></v-select>
                  <v-select :items="auswahlen['ALPSContainer']['Color']" v-model="container.ColorInnenwaende" append-icon="" label="Farbe innen" outlined clearable></v-select>
                  <v-select v-if="auswahlen[container.tbl]" :items="auswahlen[container.tbl]['Manufacturer']" v-model="container.Manufacturer" append-icon="" label="Hersteller" outlined clearable></v-select>
                  <v-combobox v-model="container.YOMBox" :items="baujahre" label="Baujahr" outlined></v-combobox>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="container.tbl=='ALPSContainer'">
            <v-expansion-panel-header class="font-weight-bold">
              <div>
                <v-icon class="mr-2">mdi-palette</v-icon>
                <span class="text-h6">Farbe</span>
              </div>
              <template v-slot:actions>
                <v-badge class="panelbadge" :color="container.Color ? 'green' : 'red'">
                  <v-icon>$expand</v-icon>
                </v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select v-if="auswahlen[container.tbl]" v-model="container.Color" :items="auswahlen[container.tbl]['Color']" label="Farbe" outlined clearable></v-select>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              <div>
                <v-icon class="mr-2">mdi-certificate</v-icon>
                <span class="text-h6">Status</span>
              </div>
              <template v-slot:actions>
                <v-badge class="panelbadge" :color="container.PurchaseCondition ? 'green' : 'red'">
                  <v-icon>$expand</v-icon>
                </v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select v-if="auswahlen[container.tbl]" v-model="container.AktuellerStatus" :items="auswahlen[container.tbl]['AktuellerStatus']" label="aktueller Status" outlined></v-select>
              <v-select v-if="auswahlen[container.tbl]" v-model="container.PurchaseCondition" :items="auswahlen[container.tbl]['PurchaseCondition']" label="aktueller Zustand" outlined></v-select>
              <v-select v-if="auswahlen[container.tbl]" v-model="container.SalesCondition" :items="auswahlen[container.tbl]['SalesCondition']" label="Verkauf Zustand" outlined></v-select>
              <v-textarea v-model="container.BemerkungPurchase" maxlength="100" counter label="Bemerkung" outlined :rules="[v => (v || '' ).length <= 100 || 'Maximal 100 Zeichen erlaubt']"></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              <div>
                <v-icon class="mr-2">mdi-screwdriver</v-icon>
                <span class="text-h6">Repa</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field min=0 max=9999 type="number" pattern="[0-9]*" step="0.25" inputmode="numeric" maxlength="6" v-model="container.AnzahlStunden" placeholder="Arbeitsstunden" outlined suffix="Std."></v-text-field>
              <v-textarea v-model="container.BemerkungRepaSonstiges" maxlength="100" counter label="Bemerkung" outlined :rules="[v => (v || '' ).length <= 100 || 'Maximal 100 Zeichen erlaubt']"></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              <div>
                <v-icon class="mr-2">mdi-tag</v-icon>
                <span class="text-h6">Sonstiges</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-textarea v-model="container.BemerkungStatus" maxlength="100" counter label="Bemerkung" outlined :rules="[v => (v || '' ).length <= 100 || 'Maximal 100 Zeichen erlaubt']"></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="d-flex">
          <v-btn class="mt-2" @click="abbrechen" >Zurück <v-icon right dark>mdi-reply</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mt-2" @click="speichern" :loading="saving" :disabled="!formOK" color="primary">Speichern <v-icon right dark>mdi-content-save</v-icon></v-btn>
        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>

/*


	[LfdNr] [int] NOT NULL,
	[Nummer] [nvarchar](20) NULL,
	[NummerPrefix] [nvarchar](10) NULL,
	[NummerBIC] [nvarchar](10) NULL,
	[NummerPruefziffer] [nvarchar](5) NULL,
	[NummerNeuPrefix] [nvarchar](10) NULL,
	[NummerNeuBIC] [nvarchar](10) NULL,
	[NummerNeuPruefziffer] [nvarchar](5) NULL,
	[CoolingUnit] [nvarchar](30) NULL,
	[PartsID] [nvarchar](20) NULL,
	[SerialNoCoolingUnit] [nvarchar](30) NULL,
	[Length] [nvarchar](30) NULL,
	[Height] [nvarchar](30) NULL,
	[Typ] [nvarchar](40) NULL,
	[Color] [nvarchar](40) NULL,
	[PreOwner] [nvarchar](30) NULL,
	[IsoCode] [nvarchar](10) NULL,
	[Manufacturer] [nvarchar](30) NULL,
	[YOMBox] [nvarchar](10) NULL,
	[ManufacturerCoolingUnit] [nvarchar](30) NULL,
	[YOMCoolingUnit] [nvarchar](5) NULL,
	[SalesCondition] [nvarchar](100) NULL,
	[BemerkungSales] [nvarchar](100) NULL,
	[PurchaseCondition] [nvarchar](100) NULL,
	[BemerkungPurchase] [nvarchar](100) NULL,
	[AktuellerStatus] [nvarchar](50) NULL,
	[BemerkungStatus] [nvarchar](100) NULL,
	[Lagerdauer] [nvarchar](20) NULL,
	[EinkaufsStandort] [nvarchar](30) NULL,
	[EinkaufsDepot] [nvarchar](60) NULL,
	[ReleaseReferenceEK] [nvarchar](30) NULL,
	[GateoutEK] [date] NULL,
	[ErhaltReleaseDatum] [date] NULL,
	[RealVerfuegbarDatum] [date] NULL,
	[GateinEK] [date] NULL,
	[TurninReferenceEK] [nvarchar](30) NULL,
	[DaysinDepotEK] [int] NULL,
	[VerkaufsStandort] [nvarchar](30) NULL,
	[VerkaufsDepot] [nvarchar](60) NULL,
	[GateinVK] [date] NULL,
	[GateoutVK] [date] NULL,
	[ReleaseReferenceVK] [nvarchar](30) NULL,
	[FrachtfuehrerAusgangVK] [nvarchar](50) NULL,
	[KennzeichenLKW] [nvarchar](20) NULL,
	[DaysinDepotVK] [int] NULL,
	[VerkaeuferName] [nvarchar](50) NULL,
	[Kommision] [nvarchar](30) NULL,
	[RechnungsNrEK] [nvarchar](30) NULL,
	[RechnungsDatumEK] [date] NULL,
	[PreisEKUSD] [decimal](15, 2) NULL,
	[PreisEKEUR] [decimal](15, 2) NULL,
	[NachlassGSUSD] [decimal](15, 2) NULL,
	[NachlassGSEUR] [decimal](15, 2) NULL,
	[Frei] [nvarchar](10) NULL,
	[ZahldatumEK] [date] NULL,
	[BemerkungVerkaeufer] [nvarchar](100) NULL,
	[Cabotage] [nvarchar](50) NULL,
	[SpeditionVorlauf] [nvarchar](30) NULL,
	[PreisVorlaufVB] [decimal](10, 2) NULL,
	[PreisVorlauf] [decimal](10, 2) NULL,
	[RechnungsNrVorlauf] [nvarchar](30) NULL,
	[SpeditionHauptstrecke] [nvarchar](30) NULL,
	[PreisHauptstreckeVB] [decimal](10, 2) NULL,
	[PreisHauptstrecke] [decimal](10, 2) NULL,
	[RechnungsNrHauptstrecke] [nvarchar](30) NULL,
	[SpeditionNachlauf] [nvarchar](30) NULL,
	[PreisNachlaufVB] [decimal](10, 2) NULL,
	[PreisNachlauf] [decimal](10, 2) NULL,
	[RechnungsNrNachlauf] [nvarchar](30) NULL,
	[RechnungsDatumVorlauf] [date] NULL,
	[RechnungsDatumHauptstrecke] [date] NULL,
	[RechnungsDatumNachlauf] [date] NULL,
	[EinkaufsDepotEK] [nvarchar](60) NULL,
	[LagergeldfreieTageEK] [int] NULL,
	[LagergeldpflichtigeTageEK] [int] NULL,
	[LagergeldSatzEK] [decimal](10, 2) NULL,
	[AktuellesLagergeldEK] [decimal](10, 2) NULL,
	[HandlingInEK] [decimal](10, 2) NULL,
	[HandlingOutEK] [decimal](10, 2) NULL,
	[ReparaturKostenEK] [decimal](10, 2) NULL,
	[RechnungsNrDepotEK] [nvarchar](30) NULL,
	[RechnungsDatumDepotEK] [date] NULL,
	[VerkaufsDepotVK] [nvarchar](60) NULL,
	[LagergeldfreieTageVK] [decimal](10, 2) NULL,
	[LagergeldpflichtigeTageVK] [int] NULL,
	[LagergeldSatzVK] [decimal](10, 2) NULL,
	[AktuellesLagergeldVK] [decimal](10, 2) NULL,
	[HandlingInVK] [decimal](10, 2) NULL,
	[HandlingOutVK] [decimal](10, 2) NULL,
	[ReparaturKostenVK] [decimal](10, 2) NULL,
	[RechnungsNrDepotVK] [nvarchar](30) NULL,
	[RechnungsDatumDepotVK] [date] NULL,
	[KostenHubHandling1] [decimal](10, 2) NULL,
	[Lagergeld1] [decimal](10, 2) NULL,
	[BemerkungHandling1] [nvarchar](50) NULL,
	[KostenHubHandling2] [decimal](10, 2) NULL,
	[Lagergeld2] [decimal](10, 2) NULL,
	[BemerkungHandling2] [nvarchar](50) NULL,
	[KostenTransporteSonstiges] [decimal](10, 2) NULL,
	[BemerkungTransporteSonstiges] [nvarchar](100) NULL,
	[KostenVerzollung] [decimal](10, 2) NULL,
	[ZollGebuehren] [decimal](10, 2) NULL,
	[BemerkungVerzollung] [nvarchar](50) NULL,
	[StundensatzWerkstatt] [decimal](10, 2) NULL,
	[AnzahlStunden] [decimal](10, 2) NULL,
	[Materialkosten] [decimal](10, 2) NULL,
	[Fremdleistungskosten] [decimal](10, 2) NULL,
	[BemerkungenReparatur] [nvarchar](50) NULL,
	[KostenNeutralisierung] [decimal](10, 2) NULL,
	[KostenExtraHandlings] [decimal](10, 2) NULL,
	[KostenRepaSonstiges] [decimal](10, 2) NULL,
	[BemerkungRepaSonstiges] [nvarchar](100) NULL,
	[KundenName] [nvarchar](150) NULL,
	[KundenNr] [nvarchar](10) NULL,
	[RechnungsNrVK] [nvarchar](20) NULL,
	[RechnungsDatumVK] [date] NULL,
	[PreisVK] [decimal](15, 2) NULL,
	[ZahldatumVK] [date] NULL,
	[Lieferterm] [nvarchar](30) NULL,
	[Land] [nvarchar](5) NULL,
	[PLZ] [nvarchar](30) NULL,
	[Lieferort] [nvarchar](150) NULL,
	[BemerkungKunde] [nvarchar](60) NULL,
	[LieferungEx] [nvarchar](100) NULL,
	[LieferungNach] [nvarchar](100) NULL,
	[SpeditionLieferung] [nvarchar](30) NULL,
	[PreisLieferungVB] [decimal](10, 2) NULL,
	[PreisLieferung] [decimal](10, 2) NULL,
	[RechnungsNrLieferung] [nvarchar](20) NULL,
	[RechnungsDatumLieferung] [date] NULL,
	[BemerkungLieferung] [nvarchar](100) NULL,
	[KostenLieferungSonstiges] [decimal](10, 2) NULL,
	[BemerkungLieferungSonstiges] [nvarchar](100) NULL,
	[KostenZubehoer] [decimal](10, 2) NULL,
	[BemerkungZubehoer] [nvarchar](100) NULL,
	[KostenSonderbau] [decimal](10, 2) NULL,
	[BemerkungSonderbau] [nvarchar](100) NULL,
	[KostenCheck] [decimal](10, 2) NULL,
	[BemerkungCheck] [nvarchar](100) NULL,
	[KostenSonstiges] [decimal](10, 2) NULL,
	[BemerkungSonstiges] [nvarchar](100) NULL,
	[KostenKauf] [decimal](10, 2) NULL,
	[KostenTransporte] [decimal](10, 2) NULL,
	[NebenkostenTransport] [decimal](10, 2) NULL,
	[KostenRepa] [decimal](10, 2) NULL,
	[KostenDepotEK] [decimal](10, 2) NULL,
	[KostenDepotVK] [decimal](10, 2) NULL,
	[KostenZubehoerSonderbau] [decimal](10, 2) NULL,
	[SummeEKTgesamt] [decimal](15, 2) NULL,
	[KostenLieferung] [decimal](10, 2) NULL,
	[HerstellkostenGesamt] [decimal](10, 2) NULL,
	[GKZuschlagProzent] [decimal](10, 5) NULL,
	[GKZuschlagBetrag] [decimal](10, 3) NULL,
	[Selbstkosten] [decimal](10, 2) NULL,
	[ZielpreisohneLieferkosten] [decimal](10, 2) NULL,
	[ErloesPreisVK] [decimal](10, 2) NULL,
	[WGZuschlag] [decimal](10, 5) NULL,
	[Rohertrag] [decimal](10, 3) NULL,
	[BemerkungKalkulation] [nvarchar](100) NULL,
	[Miete] [nvarchar](500) NULL,
	[WorkstationAndUser] [nvarchar](50) NULL,

*/

import axios from 'axios';
import ImageUploader from "vue-image-upload-resize";
import * as imageConversion from 'image-conversion';
//const { createWorker, PSM, createScheduler  } = require ('tesseract.js');

  export default {
    name: 'erfassung',
    components: {ImageUploader},
    props:['verkaufte','useState'],
    data: () => ({
      container:{
        code1: "",
        code2: "",
        code3: "",
        containertyp: null,
        SerialNoCoolingUnit: "",
        Typ: null,
        images: [],
        Length: null,
        Width: null,
        Height: null,
        BemerkungRepaSonstiges: "",
        SalesCondition: "",
        PurchaseCondition: "",
        Color: "",
        BemerkungPurchase: "",
        AnzahlStunden: null,
        BemerkungStatus: "",
        ManufacturerCoolingUnit: "",
        YOMCoolingUnit: "",
        ColorAussenwand: "",
        ColorRahmen: "",
        ColorInnenwaende: "",
        Manufacturer: "",
        YOMBox: "",
        LfdNr: "",
      },
      containerReset:{
        code1: "",
        code2: "",
        code3: "",
        containertyp: null,
        SerialNoCoolingUnit: "",
        Typ: null,
        images: [],
        Length: null,
        Width: null,
        Height: null,
        BemerkungRepaSonstiges: "",
        SalesCondition: "",
        PurchaseCondition: "",
        Color: "",
        BemerkungPurchase: "",
        AnzahlStunden: null,
        BemerkungStatus: "",
        ManufacturerCoolingUnit: "",
        YOMCoolingUnit: "",
        ColorAussenwand: "",
        ColorRahmen: "",
        ColorInnenwaende: "",
        Manufacturer: "",
        YOMBox: "",
        LfdNr: "",
      },
      code1rules: [
        value => (value || '').length == 3 || 'Zu kurz',
        value => {
          const pattern = /^[a-zA-Z]{3}$/
          return pattern.test(value) || '3x A-Z'
        },
      ],
      code2rules: [
        value => (String(value) || '').length == 6 || 'Eingabe zu kurz',
        value => {
          const pattern = /^[0-9]{6}$/
          return pattern.test(value) || '6 Ziffern'
        },
      ],
      code3rules: [
        value => {
          const pattern = /^[0-9]{1}$/
          return pattern.test(value) || 'Ziffer'
        },
      ],
      containers: [],
      auswahlen: {},
      data_loaded: false,
      bearbeitungsmodus: false,
      user: '',
      pwd: "",
      token: "",
      userdata: {},
      loading: false,
      loadingImages: false,
      uploadingImages: false,
      ocr_running: false,
      ocr_result: "",
      axios_headers: {},
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      saving: false,
      imageWidth: 2048,
      imageQuality: 0.8,
      inited: false,
    }),
    mounted(){
      this.token = window.localStorage.getItem("token")
      if (this.token){
        this.axios_headers = {
          headers: {
            'Authorization': this.token
          }
        }
        this.angemeldet()
        this.getAuswahlen(() => { 
          this.loadState() 
          this.inited = true
        } );
      }else{
        this.axios_headers = {
          headers: {}
        }
      }
      if(process.env.NODE_ENV == "development"){
        //this.container.code1 = "SUD"
        //this.container.code2 = "756989"
        //this.container.code3 = "4"

        //this.container.code1 = "PON"
        //this.container.code2 = "766716"
        //this.container.code3 = "1"

        this.container.code2 = "123456"
        
      }
    },
    methods: {
      /*
      selectAll(event){
        event.target.select();
      },
      */
      saveState(){
        if (this.inited && this.useState){
          let state = {
            container: this.container,
            containers: this.containers,
            bearbeitungsmodus: this.bearbeitungsmodus,
            data_loaded: this.data_loaded
          }
          console.log("State saved")
          window.localStorage.setItem("state",JSON.stringify(state));
        }
      },
      loadState(){
        if (this.useState){
          let s = window.localStorage.getItem("state");
          if (s){
            let state = JSON.parse(s)
            if (state){
              this.container = state.container
              this.containers = state.containers
              this.bearbeitungsmodus = state.bearbeitungsmodus
              this.data_loaded = state.data_loaded
              console.log("State loaded")
            }
          }
        }
      },
      angemeldet(){
        axios.post(process.env.VUE_APP_APIURL+"user/tokenvalid",
          null,this.axios_headers
        ).then(() => {
            console.log('Token is valid')
        }).catch((error) => {
          if (error.response.status==401){
            window.localStorage.removeItem("token")
            document.location.reload()
          }
        });
      },
      getNummer(item){
        var prefix = item.NummerNeuPrefix ? item.NummerNeuPrefix : item.NummerPrefix
        var bic = item.NummerNeuBIC ? item.NummerNeuBIC : item.NummerBIC
        var pruefz = item.NummerNeuPruefziffer ? item.NummerNeuPruefziffer : item.NummerPruefziffer
        return prefix+" "+bic+pruefz
      },
      getNummerParts(item){
        var prefix = item.NummerNeuPrefix ? item.NummerNeuPrefix : item.NummerPrefix
        var bic = item.NummerNeuBIC ? item.NummerNeuBIC : item.NummerBIC
        var pruefz = item.NummerNeuPruefziffer ? item.NummerNeuPruefziffer : item.NummerPruefziffer
        return [prefix,bic,pruefz]
      },
      resetAll(){
        this.resetForm()
        this.containers = []
      },
      filternumbers(evt){
        evt = (evt) ? evt : window.event;
        let expect = evt.target.value.toString() + evt.key.toString();
        if (!/^[0-9]+$/.test(expect)) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      filteralpha(evt){
        evt = (evt) ? evt : window.event;
        let expect = evt.target.value.toString() + evt.key.toString();
        if (!/^[A-Za-z]+$/.test(expect)) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      selectAllByRef(name){
        let inputEl = this.$refs[name].$el.querySelector('input')
        setTimeout(()=>{
            inputEl.select()
        },20)
      },
      calcCheckDigit(containernummer){
        const pattern = /^[A-Z]{4}[0-9]{6}$/
        if (pattern.test(containernummer)){
          var s = "0123456789A_BCDEFGHIJK_LMNOPQRSTU_VWXYZ"
          var a = 0
          for(var i=0;i<10;i++){
            var l = containernummer.substr(i,1)
            var v = s.indexOf(l)
            var m = Math.pow(2,i)
            //console.log(l,v,m, m*v);
            a += m * v
          }
          var b = Math.floor(a / 11)
          var d = b * 11;
          var checkdigit = a - d;
          if (checkdigit==10) checkdigit = 0
          //console.log('calc checkdigit',containernummer,checkdigit,a,b,d)
          return checkdigit
        }
        return "";
      },
      codeeingabe(feld,val){

        //if (this.bearbeitungsmodus) return true;

        //console.log(feld,val);
        if (!val) return;
        switch(feld){
          case "code1":
              this.container.code1 = val.toUpperCase();
              if (val.length>=3){
                this.$refs["code2"].focus();
                this.selectAllByRef("code2");
              }
            break;

          case "code2":
              if (val.length>=6){
                var checkdigit = String(this.calcCheckDigit(this.container.code1+"U"+this.container.code2))
                //console.log('checkdigit',checkdigit);
                if (checkdigit!=""){
                  //this.container.code3 = checkdigit
                  this.$refs["code2"].blur();
                  this.laden()
                }else{
                  //this.$refs["code3"].focus();
                  //this.selectAllByRef("code3");
                }
              }
            break;

          case "code3":
              if (val.length>=1){
                this.$refs["code3"].blur();
                this.laden()
              }
            break;
        }
      },
      resetForm(){
        this.data_loaded = this.containers.length <=0 ? false : true;
        var c = Object.assign({},this.container)
        this.$refs.form.reset()
        this.$nextTick( () =>{
          this.$set(this.container,"code1",c.code1)
          this.$set(this.container,"code2",c.code2)
          this.$set(this.container,"code3",c.code3)
        })
      },
      async getAuswahlen(callback){
        axios.get(process.env.VUE_APP_APIURL+"container/getAuswahlen",
          this.axios_headers
        ).then(result => {
            if (result.status=="200"){
                for(var k in result.data){
                  this.$set(this.auswahlen,k,result.data[k]);
                  if (typeof callback == "function") callback()
                }
            }else{
              this.snackbar_text = "Gatewayfehler: "+result.status
              this.snackbar_color = "error"
              this.snackbar = true;
            }
            this.loading = false;
        }).catch(() => {
            this.snackbar_text = "Laden der Auswahlmöglichkeiten fehlerhaft"
            this.snackbar_color = "error"
            this.snackbar = true;
            this.loading = false;
        });
      },
      async login(){
        this.loading = true;
        axios.post(process.env.VUE_APP_APIURL+"user/get_user_by_login",
          { "user":this.user,"pwd":this.pwd},
          this.axios_headers
        ).then(result => {
            if (result.status=="200" && result.data){
              window.localStorage.setItem("token",result.data.token)
              document.location.reload()
            }else{
              this.snackbar_text = "Gatewayfehler: "+result.status
              this.snackbar_color = "error"
              this.snackbar = true;
            }
            this.loading = false;
        }).catch((res) => {
            console.log(res);
            this.snackbar_text = "Login fehlgeschlagen"
            this.snackbar_color = "red"
            this.snackbar = true;
            this.loading = false;
        });
      },
      async laden(){
        this.loading = true;
        this.data_loaded = false;
        axios.post(process.env.VUE_APP_APIURL+"container/get",
          [this.container.code1+'U',this.container.code2,this.container.code3],
          this.axios_headers
        ).then(result => {
            if (result.status=="200"){
              this.containers = result.data
              this.bearbeitungsmodus = false;
              this.data_loaded = true;
              if (this.containers.length==0){
                this.snackbar_text = "Kein Treffer unter diesen Angaben"
                this.snackbar_color = "error"
                this.snackbar = true;
              }
            }else{
              this.snackbar_text = "Gatewayfehler: "+result.status
              this.snackbar_color = "error"
              this.snackbar = true;
            }
            this.loading = false;
        }).catch(() => {
            this.snackbar_text = "Laden fehlerhaft"
            this.snackbar_color = "error"
            this.snackbar = true;
            this.loading = false;
        });
      },
      bearbeiten(item){
        this.resetForm()
        this.bearbeitungsmodus = true;
        this.$nextTick( () => {
          for(var k in item){
            this.$set(this.container,k,item[k]);
          }   

          if (this.container["AnzahlStunden"]==0) this.$set(this.container,"AnzahlStunden",null);

          var parts = this.getNummerParts(item);
          this.$set(this.container,"code1",parts[0].substring(0,3));
          this.$set(this.container,"code2",parts[1]);
          this.$set(this.container,"code3",parts[2].substring(1,2));

          this.getImages()
        })
      },
      getImages(){
        this.loadingImages = true
        axios.post(process.env.VUE_APP_APIURL+"container/getImages",
          [this.container.LfdNr,this.container.tbl,true],
          this.axios_headers
        ).then(result => {
            this.container.images = result.data
            this.loadingImages = false;
        }).catch(() => {
            this.snackbar_text = "Kann Bilder nicht laden"
            this.snackbar_color = "error"
            this.snackbar = true;
            this.loadingImages = false;
        });
      },
      handleImage(e){
        const selectedImage = e.target.files[0];
        if (selectedImage){
          imageConversion.compress( e.target.files[0],{
            quality: this.imageQuality,
            type: "image/jpeg",
            width: this.imageWidth
          }).then(res=>{
            imageConversion.filetoDataURL(res).then((r) => {
              console.log('Imagesize',Math.round(r.length / 1024),'kb')
              let o = {'dataUrl' : r,'info' : { state : 'new', name : selectedImage.name, type : res.type, modifiedTimestamp: selectedImage.lastModified}}
              o.info.name = this.getImageName(o.info.name)
              this.uploadingImages = true
              axios.post(process.env.VUE_APP_APIURL+"container/createTempThumb",
                [this.container.LfdNr,true,o],
                this.axios_headers
              ).then(result => {
                //o.dataThumb = result.data.result
                o.dataUrl = result.data.result
                this.$set(this.container.images,this.container.images.length,o)
                this.uploadingImages = false
              }).catch(() => {
                  this.snackbar_text = "Kann aktuelles Bild nicht verarbeiten"
                  this.snackbar_color = "error"
                  this.snackbar = true;
                  this.uploadingImages = false
              });
            })
      
          })
        }
        /*
        if(selectedImage){
          const reader = new FileReader();
          reader.onload = (e) => {
            let o = {'dataUrl' : e.target.result,'info' : { state : 'new', name : selectedImage.name, type : selectedImage.type, modifiedTimestamp: selectedImage.lastModified}}
            o.info.name = this.getImageName(o.info.name)
            this.$set(this.container.images,this.container.images.length,o)

          }
          reader.readAsDataURL(selectedImage)
        }
        */
      },
      appendImage: function(output) {
        try {
          //  Nur OCR wenn nichts angegeben ist
          /*
          if ((this.code1=="" || this.code2=="" || this.code3=="") && !this.ocr_running){
            this.ocr_running = true

            // https://stackoverflow.com/questions/63292723/how-to-use-tesseract-js-on-a-base64-encoded-image

            let base64 = output.dataUrl;
            base64 = base64.split(";base64,")[1]
            let imageBuffer = Buffer.from(base64, "base64");

            const scheduler = createScheduler();
            const worker1 = createWorker();
            const worker2 = createWorker();

            //https://github.com/naptha/tesseract.js/blob/HEAD/docs/api.md#worker-load-language
            (async () => {
                await worker1.load();
                await worker1.loadLanguage('deu');
                await worker1.initialize('deu');
                await worker1.setParameters({
                  tessedit_char_whitelist: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ ',
                  tessedit_pageseg_mode: PSM.PSM_SINGLE_CHAR,
                  //tessedit_pageseg_mode: PSM.PSM_SINGLE_CHAR,
                  //tessedit_pageseg_mode: PSM.PSM_SINGLE_BLOCK_VERT_TEXT,
                });

                await worker2.load();
                await worker2.loadLanguage('deu');
                await worker2.initialize('deu');
                await worker2.setParameters({
                  tessedit_char_whitelist: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ ',
                  tessedit_pageseg_mode: PSM.PSM_SINGLE_BLOCK_VERT_TEXT,
                });

                scheduler.addWorker(worker1);
                scheduler.addWorker(worker2);
                
                this.ocr_result = ""
                console.log("Recognizing...");
                //var rec = await worker1.recognize(imageBuffer);
                //console.log("Rec",rec);

                var rec = await Promise.all(Array(2).fill(0).map(() => (
                  scheduler.addJob('recognize', imageBuffer)
                )))

                //console.log(rec);
                await scheduler.terminate(); // It also terminates all workers.

                
                //const { data: { text } } = await worker.recognize(imageBuffer);
                for(var worker of rec){
                  console.log(worker.data);
                  this.ocr_result+= worker.data.text+" <hr /> "
                  let matches = worker.data.text.match(/([A-Z]{4})\s+([0-9]{6})\s+([0-9]{1})/);
                  if (matches){
                    if (this.container.code1=="") this.$set(this.container,'code1',matches[1])
                    if (this.container.code2=="") this.$set(this.container,'code2',matches[2])
                    if (this.container.code3=="") this.$set(this.container,'code3',matches[3])
                  }
                }
                
                this.ocr_running = false
                //console.log("Recognized text:", rec.data.text);
                //await worker.terminate();
            })();
          }
          */
          output.info.state = "new"
          output.info.name = this.getImageName(output.info.name)
          this.$set(this.container.images,this.container.images.length,output)
        }catch(error){
          alert(error);
        }
      },
      getImageName(name){
        if (this.imageExists(name)){
          name = Date.now()+name;
          if (this.imageExists(name)){
            name = Date.now()+name;
          }
        }
        return name
      },
      imageExists(name){
        for(var img of this.container.images){
          if (img.info.name==name) return true
        }
        return false
      },
      delImg: function(idx){
        var delIdx = this.container.images.length-1 - idx
        if (this.container.images[delIdx].info.state == "new"){
          this.container.images.splice(delIdx,1)
        }else{
          this.container.images[delIdx].info.state = "deleted"
          this.container.images[delIdx].dataUrl = ""
        }
      },
      anlegen(){
        var code1 = this.container.code1
        var code2 = this.container.code2
        var code3 = this.container.code3
        this.container = Object.assign({},this.containerReset)
        this.resetForm()
        this.bearbeitungsmodus = true;
        this.$nextTick( () => {
          this.$set(this.container,"LfdNr",null);
          this.$set(this.container,"tbl",null);
          this.$set(this.container,"code1",code1);
          this.$set(this.container,"code2",code2);
          this.$set(this.container,"code3",code3);
        })
      },
      speichern(){
        this.saving = true
        var data = JSON.parse(JSON.stringify(this.container))
        for(var i in data.images){
          if (data.images[i].info.state=="exists") data.images[i].dataUrl = ""
        }
        axios.post(process.env.VUE_APP_APIURL+"container/save",{ "data" : data },this.axios_headers)
        .then(result => {
            
          if (result.status=="200" && result.data.result){
              if (result.data.result) this.container.LfdNr = result.data.result

              this.snackbar_text = "Speichern erfolgreich"
              this.snackbar_color = "success"
              this.snackbar = true;
            }else if(result.status=="200" && !result.data.result){
              this.snackbar_text = "Fehler beim speichern"
              this.snackbar_color = "error"
              this.snackbar = true;
            }else{
              this.snackbar_text = "Gatewayfehler: "+result.status
              this.snackbar_color = "error"
              this.snackbar = true;
            }

            this.loading = false
            this.saving = false
            this.bearbeitungsmodus = false;
            this.data_loaded = false;
            this.$refs.form.reset()
            this.$set(this.container,"code1","")
            this.$set(this.container,"code2","")
            this.$set(this.container,"code3","")
            this.$nextTick( () => {
              this.$refs["code1"].focus();
            })
        }).catch(() => {
            this.snackbar_text = "Speichern des Datensatzes fehlerhaft"
            this.snackbar_color = "error"
            this.snackbar = true
            this.loading = false
            this.saving = false
        });
      },
      abbrechen(){
        this.bearbeitungsmodus = false;
        this.resetForm()
      }
    },
    computed:{
      containerliste(){
        if (!this.verkaufte) return this.containers.filter( item => item.AktuellerStatus!="Sold – delivered")
        return this.containers
      },
      auswahlenCombo(){
        if (!this.auswahlen.typen) return {}
        let d = {"typen": Array.from(this.auswahlen.typen)}
        for (var t in this.auswahlen){
          if (t=="typen") continue
          if (!d[t]) d[t] = {}
          for(var feld in this.auswahlen[t]){
            //var items = this.auswahlen[t][feld]
            if (!d[t][feld]) d[t][feld] = []
            for(var item of this.auswahlen[t][feld]){
              d[t][feld].push(item.text)
            }
          }
        }
        return d
      },
      page(){
        if (!this.token) return "login"
        return "container"
      },
      imagefiles(){
        if (this.container.images.length==0) return []
        var a = Array.from(this.container.images);
        return a.reverse()
      },
      baujahre(){
        var currentYear = new Date().getFullYear()
        var jahre = []
        for(var j=currentYear;j>=1980;j--){
          jahre.push(j)
        }
        return jahre
      },
      containerNrOk(){
        if (!this.container.code1) return false
        if (!this.container.code2) return false
        if (!this.container.code3) return false
        return this.container.code1.length==3 && this.container.code2.length==6 && this.container.code3.length==1
      },
      containerNrTeileingabe(){
        return this.container.code1.length>0 || this.container.code2.length>0
      },
      formOK(){
        if (!this.containerNrOk) return false
        if (!this.container.tbl) return false
        return true
      },
      selectedTyp(){
        var r = this.auswahlen.typen.filter( item => {
          return item.tbl==this.container.tbl
        })
        if (r && r.length==1) return r[0].name
        return ""
      }
    },
    watch:{
      'container.code1'(newVal){
        this.container.code3 = String(this.calcCheckDigit(newVal+"U"+this.container.code2))
      },
      'container.code2'(newVal){
        this.container.code3 = String(this.calcCheckDigit(this.container.code1+"U"+newVal))
      },
      container:{
        handler: function(){
          this.saveState()
        },
        deep: true
      },
      containers:{
        handler: function(){
          this.saveState()
        },
        deep: true
      },
      bearbeitungsmodus:{
        handler: function(){
          this.saveState()
        },
        deep: true
      },
      data_loaded:{
        handler: function(){
          this.saveState()
        },
        deep: true
      }
    }
  }
</script>

<style>
.badge_loading .v-badge__badge{
  padding-left: 0px;
  padding-right: 0px;
  padding-top:3px
}

#fileInput {
  display: none;
}

.v-snack__content{
  text-align: center !important;
}
.containernummer .v-text-field input {
  font-size: 2.3em !important;
  font-weight: bold;
  max-height: 90%;
}
.v-list-item__title,
.v-select__selection,
.v-text-field input {
  font-size: 1.4em;
  font-weight: bold;
}

.v-label:not(.v-label--active){
  transform: translateY(-5px) scale(1.5);
}

.imageeditable{
  border:1px solid #aaaaaa;
  border-radius: 5px;
}

.containernummer .v-input__slot{
    padding-right: 3px !important;
    padding-left: 6px !important;
}

.containernummer .v-text-field__suffix{
    font-weight: bold;
    color: black;
    font-size: 2.3em;
    padding-left: 1px !important;
}
.liste .ALPSContainer{
  border-left: 5px solid coral;
}
.liste .ALPSMietContainer{
  border-left: 5px solid green;
}
.liste .ALPSRaumContainer{
  border-left: 5px solid blue;
}

.legende .ALPSContainer,
.legende .ALPSMietContainer,
.legende .ALPSRaumContainer{
  width:10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.legende .ALPSContainer{
  background-color: coral;
}
.legende .ALPSMietContainer{
  background-color: green;
}
.legende .ALPSRaumContainer{
  background-color: blue;
}

.panels{
  border-left: 5px solid transparent;
}

.panels.ALPSContainer{
  border-left: 5px solid coral;
}
.panels.ALPSMietContainer{
  border-left: 5px solid green;
}
.panels.ALPSRaumContainer{
  border-left: 5px solid blue;
}

.containernummer .theme--light.v-input--is-disabled input,
.panels .theme--light.v-select .v-select__selection--disabled {
  color: rgba(14, 21, 53, 0.7) !important; 
}
</style>
